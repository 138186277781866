.displayBlock {
  display: block;
  width: 100%;
}

.payLaterTooltip,
.selectorToggle {
  width: 100%;
}

.payLaterTooltip .tooltipDisabled:hover {
  box-shadow: var(--shadow-low);
}

.title {
  display: flex;
  align-items: center;
}

.tooltipTrigger {
  all: unset;
  display: flex;
  width: 100%;
  cursor: pointer;

  &:hover::after {
    --overlay-opacity: 0;
  }
}
