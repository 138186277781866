.cell {
  padding-right: 24px;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);

  &.empty {
    width: 48px;
    border-bottom: none;
  }

  @media only screen and (width < 1368px) {
    border-bottom: none;
  }
}

.connection {
  display: flex;
  gap: 16px;
  align-items: center;
}

.connection-avatar {
  width: 32px;
  height: 32px;
}

.connection-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.member-name {
  composes: body-2 from global;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.member-team {
  composes: caption from global;
  color: var(--content-secondary);
  text-transform: capitalize;
}

.status {
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 12px;
  }

  &.failure {
    color: var(--content-error);
  }

  &.error {
    color: var(--content-error);
  }
}

.hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  visibility: hidden;
}
