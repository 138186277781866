.connection-sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 16px 16px;
  background-color: var(--elevation-low);
}

.title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
}

.close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.api-client {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 56px;
  margin-top: 32px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.api-client-avatar {
  position: absolute;
  top: -32px;
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
}

.api-client-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 24px;
  text-align: center;
}

.disclaimer {
  width: 100%;
  padding: 16px;
  border-top: 1px solid var(--border-tertiary);

  /* Force an error color to emphasis the data inaccuracy */
  &.expired svg path {
    fill: var(--content-error);
  }
}

.connect-button {
  border-radius: 0 0 8px 8px;

  /* Force the icon to be the same color as the text, since the button is using the primary color */
  svg {
    color: currentcolor;
  }
}
