.thead {
  color: var(--invoice-color-text-alt);
  white-space: nowrap;
  background: var(--invoice-color-theme);

  th {
    overflow: hidden;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 6px;

    &.description {
      width: 312px;
      text-align: left;
      padding: 4px 12px;
    }

    &.description-de {
      width: 257px;
      text-align: left;
      padding: 4px 12px;
    }

    &.quantity {
      width: 50px;
      text-align: right;
    }

    &.amount {
      width: 90px;
      text-align: right;
    }

    &.vat {
      width: 60px;
      text-align: right;
    }

    &.subtotal {
      width: 100px;
      text-align: right;
      padding: 4px 12px;
    }
  }
}
