.cell {
  padding-right: 24px;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);

  &.empty {
    width: 48px;
    border-bottom: none;
  }
}

.connection {
  display: flex;
  gap: 16px;
  align-items: center;
}
