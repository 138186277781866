.centered {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  color: var(--content-primary);
  text-align: center;

  .qonto-logo {
    width: 48px;
    height: 48px;
  }

  .subtitle {
    color: var(--content-secondary);
  }

  .cta-button {
    width: 100%;
    margin-top: 16px;

    .cta {
      display: flex;
      gap: 4px;
      align-items: center;

      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
