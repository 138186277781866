.row {
  &:not(.no-bottom) {
    border-bottom: 1px solid var(--gray300);
  }

  td {
    padding: 12px 6px;
    white-space: nowrap;

    &.description-cell {
      text-align: left;
      white-space: normal;
      padding: 12px;

      .title {
        margin: 2px 0;
      }

      .description {
        color: var(--invoice-color-text-66);
        white-space: pre-line;
      }
    }

    &.quantity-cell {
      text-align: right;
    }

    &.amount-cell {
      text-align: right;
    }

    &.vat-cell {
      text-align: right;
    }

    &.subtotal-cell {
      text-align: right;
      padding: 12px;
    }
  }
}
