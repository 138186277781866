.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-default);
  display: flex;
  gap: 80px;
  width: 100%;
  height: auto;
  padding-left: 48px;
  background-color: var(--elevation-low);
  border-top: 1px dashed var(--border-secondary);
  border-bottom: 1px dashed var(--border-secondary);
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon,
.subtitle,
.title {
  color: var(--content-secondary);
}
